import React, { useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import StickyLogo from "../../../assets/NewSite/theleansuite-logo.jpg";
import Logo from "../../../assets/NewSite/white-logo.webp";
import "./Navbar.css";
import { styled } from "styled-components";
import { Color } from "../../../theme/Color";
import { useDispatch, useSelector } from "react-redux";
import { openNavbarMenu } from "../../../redux/actions/navbarAction";
import { Link, useHistory } from "react-router-dom";
import menuimg from "../../../assets/NewSite/menu-img.webp";
import safetymenuimg from "../../../assets/NewSite/safety_improvement.webp";
import qualitymenuimg from "../../../assets/NewSite/quality_improvement.webp";
import operationalmenuimg from "../../../assets/NewSite/operational_excellence.webp";
import costmenuimg from "../../../assets/NewSite/cost_reduction.webp";
import workforcemenuimg from "../../../assets/NewSite/workforce_enablement.webp";
import aboutmenuimg from "../../../assets/NewSite/our_story.webp";
import tagimg from "../../../assets/small assests/tag_management_system.webp";
import continuousimg from "../../../assets/small assests/continuous_improvement_system.webp";
import lmsimg from "../../../assets/small assests/learning_management_system.webp";
import aboutimg from "../../../assets/small assests/mdi_about.webp";
import partnerimg from "../../../assets/small assests/partner.webp";
import pressimg from "../../../assets/small assests/press.webp";
import contactimg from "../../../assets/small assests/contact.webp";
import ebook from "../../../assets/small assests/e_books.webp";
import templates from "../../../assets/small assests/template.webp";
import videos from "../../../assets/small assests/videos.webp";
import webinars from "../../../assets/small assests/webinars.webp";
import blogs from "../../../assets/small assests/blogs.webp";
import { MdSearch, MdEmail, MdCollectionsBookmark } from "react-icons/md";
import { IoMdInformationCircle } from "react-icons/io";
import { FaHandsHelping } from "react-icons/fa";
import { BiSolidVideos } from "react-icons/bi";
import { HiTemplate } from "react-icons/hi";
import { RiFileList3Fill } from "react-icons/ri";
import { TbWorld } from "react-icons/tb";
import { FaFileLines } from "react-icons/fa6";

import { Font } from "../../../theme/Font";
import { BigYellowLinkButton } from "../../DesignComponent/DesignComponents";
import { getAllBlogsAction } from "../../../redux/actions/blogsList";

const NewNavbarComponent = (props) => {
  const blogList = useSelector((state) => state?.allblogs?.blogsList);
  const blog = blogList[0];

  const Button = styled(Link)`
    text-transform: capitalize;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 2px;
    border: 2px solid ${Color.yelow};
    background-color: ${Color.yelow};
    color: ${Color.theme_first};
    transition: 0.8s all;
    font-family: ${Font.basel};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    // margin: 0 1rem;
    transition: box-shadow .3s cubic-bezier(.25, .46, .45, .94);
    &:hover {
      background-color: ${Color.yelow} !important;
    color: ${Color.theme_first};
      // border-color: ${Color.yelow};
      box-shadow: 0 1px 5px rgba(150,150,150,.5);
    }
    @media (max-width: 767px) {
      margin: 1rem 0 0.8rem 0;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      margin: 0.5rem 0;
    }
  `;
  const CustomeSpan = styled.div`
    color: ${Color.theme_secound};
    font-family: ${Font.bold};
    font-weight: 700;
    font-size:20px;
    &:hover {
      cursor: pointer;
    }
  `;
  const LearnMore = styled.a`
    color: ${Color.theme_secound};
    font-family: ${Font.basel};
    font-weight: bold;
    text-decoration-line: none;
  `;

  const NavIcon = styled.img`
  width: 42px;
  border:1px solid #1D1C4CCC;
  border-radius:5px;
  padding:9px;
  // color:${Color.theme_first};
  &:hover{
    // background-color: ${Color.theme_first};
    // border:1px solid ${Color.theme_first};
    // color:${Color.white};
  }
  `;

  const MenuNavIcon = styled.div`
  width: 42px;
  border:1px solid #1D1C4CCC;
  border-radius:5px;
  padding:9px;
  color:${Color.theme_first};
  &:hover{
    background-color: ${Color.theme_first};
    border:1px solid ${Color.theme_first};
    color:${Color.white};
  }
  `;

  const dispatch = useDispatch();
  const navigate = useHistory();
  const [openProduct, setOpenProduct] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const isNav = useSelector((state) => state?.isNavOpen?.isOpen);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [outsideClicked, setOutsideClicked] = useState(false);
  const navbarRef = useRef(null);

  const handleOutsideClick = (e) => {
    if(navbarRef.current && !navbarRef.current.contains(e.target)){
      setIsMenuOpen(false);
    }
    // if (navbarRef.current && !navbarRef.current.contains(e.target)) {
    //   setOutsideClicked(true);
    // } else {
    //   setOutsideClicked(false);
    // }
  };

  const showDropdown = !outsideClicked;

  useEffect(() => {
    dispatch(getAllBlogsAction());
  }, [dispatch, props]);

  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // const handleMouseEnter = () => {
  //   setIsMenuOpen(true);
  //   dispatch(openNavbarMenu(true));
  // };

  // const handleMouseLeave = () => {
  //   dispatch(openNavbarMenu(false));
  // };
  const handleNavigate = (link) => {
    // dispatch(openNavbarMenu(false));
    navigate.push(link);
    setIsMenuOpen(false);
    setIsMobileMenuOpen(false);
  };

  const handleMouseEnter = (menuItem) => {
    setIsMenuOpen(true);
    setOpenProduct(menuItem);
  };

  const handleMouseLeave = () => {
    // setIsMenuOpen(false);
  };
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavClick = () => {
    setActiveMenuItem("Platform");
  };
  const closeModal = () => {
    setIsMenuOpen(false);
    setIsMobileMenuOpen(false);
    // console.log("closeModal--------");
  };

  // useEffect(() => {
  //   dispatch(openNavbarMenu(isMenuOpen));
  // }, [isMenuOpen]);

  const bookDemo = () => {
    closeModal();
    navigate.push("/schedule-demo");
    toggleMobileMenu();
  };

  const [isSticky, setSticky] = useState(false);
  const [logoSrc, setLogoSrc] = useState(Logo);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setSticky(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Update logo source when navigation becomes sticky
  useEffect(() => {
    if (isSticky) {
      setLogoSrc(StickyLogo); // Set logo source for sticky navigation
    } else {
      setLogoSrc(Logo); // Set logo source for default navigation
    }
  }, [isSticky]);

  return (
    <>
      <Navbar
        ref={navbarRef}
        expand="xl"
        className={`navbar-dark py-3 desktop-nav d-xl-block d-none ${isSticky ? 'sticky' : ''}`}
        style={{ backgroundColor: isSticky ? '#fff' : '#1c1c4b' }}
      >
        <Container>
          <Navbar.Brand>
            <Link to={"/"} onClick={closeModal}>
              <img src={logoSrc} alt="theleansuite logo" style={{ maxWidth: "145px" }} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Item
                className=""
                onClick={() => handleMouseEnter("mainPlatform")}
                onMouseLeave={handleMouseLeave}
              >
                <Nav.Link
                  eventKey="1"
                  className="link-nav"
                  onClick={() => setActiveMenuItem("SafetyImprovement")}
                  onMouseLeave={() => setActiveMenuItem("SafetyImprovement")}
                >
                  Platform
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                onClick={() => handleMouseEnter("mainCompany")}
                onMouseLeave={handleMouseLeave}
                className=""
              >
                <Nav.Link eventKey="1" className="link-nav">
                  Company
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className=""
                onClick={() => handleMouseEnter("mainResources")}
                onMouseLeave={handleMouseLeave}
              >
                <Nav.Link eventKey="1" className="link-nav">
                  Resources
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  eventKey="1"
                  as={Link}
                  to="/home"
                  className="link-nav"
                  style={{ display: "none" }}
                >
                  Success
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  eventKey="1"
                  as={Link}
                  onClick={closeModal}
                  to="/pricing"
                  className="link-nav"
                >
                  Pricing
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Nav.Link
              eventKey="1"
              // as={Link}
              href={"https://theleansuite.app/"}
              className="link-nav"
              onClick={closeModal}
              target="_blank"
            >
              Login
            </Nav.Link>
            <Button className="text-decoration-none" to={"/schedule-demo"}>Schedule demo</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div
        // onMouseEnter={handleMouseEnter}
        // ref={navbarRef}
        onClick={() => setIsMenuOpen(false)}
        className={`menu-content bg-white ${isMenuOpen ? "active" : ""}`}
      >
        {isMenuOpen == true && openProduct === "mainPlatform" && (
          <div className="your-platform-content">
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-md-4 submenu-container"
                  onMouseEnter={() => handleMouseEnter("mainPlatform")}
                  onMouseLeave={handleMouseLeave}
                  style={{ background: Color.theme_four, padding: "34px 0 34px 34px" }}
                >
                  <ul className="list-group">
                    <li
                      className="list-group-item has-submenu rounded-0"
                      //   onClick={() => handleNavigate("/what-is-leansuite")}
                      onMouseEnter={() => setActiveMenuItem("SafetyImprovement")}
                      style={{
                        background:
                          activeMenuItem === "SafetyImprovement"
                            ? Color.white
                            : "initial",
                        borderWidth: "0 0 0 4px",
                        borderColor:
                          activeMenuItem === "SafetyImprovement" ? "#ffc000" : "transparent",
                      }}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Safety Improvement</CustomeSpan>
                      </div>
                    </li>
                    <li
                      className="list-group-item has-submenu rounded-0"
                      onMouseEnter={() => setActiveMenuItem("QualityImprovement")}
                      style={{
                        background:
                          activeMenuItem === "QualityImprovement"
                            ? Color.white
                            : "initial",
                        borderWidth: "0 0 0 4px",
                        borderColor:
                          activeMenuItem === "QualityImprovement" ? "#ffc000" : "transparent",
                      }}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Quality Improvement</CustomeSpan>
                      </div>
                    </li>
                    <li
                      className="list-group-item has-submenu rounded-0"
                      onMouseEnter={() => setActiveMenuItem("OperationalExcellence")}
                      style={{
                        background:
                          activeMenuItem === "OperationalExcellence"
                            ? Color.white
                            : "initial",
                        borderWidth: "0 0 0 4px",
                        borderColor:
                          activeMenuItem === "OperationalExcellence" ? "#ffc000" : "transparent",
                      }}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Operational Excellence</CustomeSpan>
                      </div>
                    </li>
                    <li
                      className="list-group-item has-submenu rounded-0"
                      onMouseEnter={() => setActiveMenuItem("CostReduction")}
                      style={{
                        background:
                          activeMenuItem === "CostReduction"
                            ? Color.white
                            : "initial",
                        borderWidth: "0 0 0 4px",
                        borderColor:
                          activeMenuItem === "CostReduction" ? "#ffc000" : "transparent",
                      }}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Cost Reduction</CustomeSpan>
                      </div>
                    </li>
                    <li
                      className="list-group-item has-submenu rounded-0"
                      onMouseEnter={() => setActiveMenuItem("WorkforceEnablement")}
                      style={{
                        background:
                          activeMenuItem === "WorkforceEnablement"
                            ? Color.white
                            : "initial",
                        borderWidth: "0 0 0 4px",
                        borderColor:
                          activeMenuItem === "WorkforceEnablement" ? "#ffc000" : "transparent",
                      }}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Workforce Enablement</CustomeSpan>
                      </div>
                    </li>
                  </ul>
                </div>

                {activeMenuItem != "" && (
                  <>
                    {activeMenuItem == "SafetyImprovement" && (
                      <>
                        <div
                          className="col-md-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <img src={safetymenuimg} className="w-100 rounded mb-2" alt={"menuimg"} />
                            <CustomeSpan className="fw-5 mb-2" style={{ fontFamily: Font.basel }}>Safety Improvement</CustomeSpan>
                            <p>With our comprehensive and user-friendly safety improvement tools, you can streamline your processes, track progress, and enhance overall safety measures with ease</p>
                            <LearnMore href="/safety-improvement">Learn More</LearnMore>
                          </div>
                        </div>
                        <div
                          className="col-md-4 pt-40 px-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <ul>
                              <li className="list-group-item">
                                <a href="/issue-tracker-for-safety" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={tagimg} alt="tagimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Tag Management System</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Identify, capture, and resolve safety-related issues from one place</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li className="list-group-item">
                                <a href="/continuous-improvement-for-operations" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={continuousimg} alt="continuousimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Continuous Improvement System</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Create improvement projects and eliminate potential hazards for good</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li className="list-group-item">
                                <a href="/learning-management-system" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={lmsimg} alt="lmsimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Learning Management System</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Train, develop, and keep your employees up-to-date on safety protocols</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                    {activeMenuItem == "QualityImprovement" && (
                      <>
                        <div
                          className="col-md-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <img src={qualitymenuimg} className="w-100 rounded mb-2" alt={"menuimg"} />
                            <CustomeSpan className="fw-5 mb-2" style={{ fontFamily: Font.basel }}>Quality Improvement</CustomeSpan>
                            <p>Streamline the process of monitoring and enhancing quality across your organization</p>
                            <LearnMore href="/quality-improvement">Learn More</LearnMore>
                          </div>
                        </div>
                        <div
                          className="col-md-4 pt-40 px-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <ul>
                              <li className="list-group-item">
                                <a href="/issue-tracker-for-safety" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={tagimg} alt="tagimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Tag Management System</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Identify, capture, and resolve safety-related issues from one place</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li className="list-group-item">
                                <a href="/qa-matrix" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={continuousimg} alt="continuousimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Quality Assurance (QA) Matrix</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Visually see all your points of improvement and address your most critical quality-related issues first</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                    {activeMenuItem == "OperationalExcellence" && (
                      <>
                        <div
                          className="col-md-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <img src={operationalmenuimg} className="w-100 rounded mb-2" alt={"menuimg"} />
                            <CustomeSpan className="fw-5 mb-2" style={{ fontFamily: Font.basel }}>Operational Excellence</CustomeSpan>
                            <p>Lorem ipsum dolor sit amet consectetur. Eu blandit convallis duis varius augue feugiat.</p>
                            <LearnMore href="/operational-excellence">Learn More</LearnMore>
                          </div>
                        </div>
                        <div
                          className="col-md-4 pt-40 px-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <ul>
                              <li className="list-group-item">
                                <a href="/issue-tracker-for-safety" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={tagimg} alt="tagimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Tag Management System</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Identify, capture, and resolve safety-related issues from one place</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li className="list-group-item">
                                <a href="/qa-matrix" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={continuousimg} alt="continuousimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Quality Assurance (QA) Matrix</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Visually see all your points of improvement and address your most critical quality-related issues first</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                    {activeMenuItem == "CostReduction" && (
                      <>
                        <div
                          className="col-md-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <img src={costmenuimg} className="w-100 rounded mb-2" alt={"menuimg"} />
                            <CustomeSpan className="fw-5 mb-2" style={{ fontFamily: Font.basel }}>Cost Reduction</CustomeSpan>
                            <p>Streamline the process of monitoring and enhancing quality across your organization</p>
                            <LearnMore href="/cost-reduction">Learn More</LearnMore>
                          </div>
                        </div>
                        <div
                          className="col-md-4 pt-40 px-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <ul>
                              <li className="list-group-item">
                                <a href="/issue-tracker-for-safety" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={tagimg} alt="tagimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Tag Management System</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Identify, capture, and resolve safety-related issues from one place</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li className="list-group-item">
                                <a href="/qa-matrix" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={continuousimg} alt="continuousimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Quality Assurance (QA) Matrix</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Visually see all your points of improvement and address your most critical quality-related issues first</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                    {activeMenuItem == "WorkforceEnablement" && (
                      <>
                        <div
                          className="col-md-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <img src={workforcemenuimg} className="w-100 rounded mb-2" alt={"menuimg"} />
                            <CustomeSpan className="fw-5 mb-2" style={{ fontFamily: Font.basel }}>Workforce Enablement</CustomeSpan>
                            <p>Streamline the process of monitoring and enhancing quality across your organization</p>
                            <LearnMore href="/workforce-enablement">Learn More</LearnMore>
                          </div>
                        </div>
                        <div
                          className="col-md-4 pt-40 px-4 d-flex flex-wrap"
                          style={{
                            alignItems:
                              activeMenuItem == "Platform" ? "center" : "start", padding: "34px"
                          }}
                          onMouseEnter={() => handleMouseEnter("mainPlatform")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="content-leansuite w-100">
                            <ul>
                              <li className="list-group-item">
                                <a href="/issue-tracker-for-safety" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={tagimg} alt="tagimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Tag Management System</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Identify, capture, and resolve safety-related issues from one place</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li className="list-group-item">
                                <a href="/qa-matrix" className="text-decoration-none">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img src={continuousimg} alt="continuousimg" className="me-2" width="42px" />
                                    <div className="card-content">
                                      <LearnMore>Quality Assurance (QA) Matrix</LearnMore>
                                      <p className="fs-12 text-dark mb-0">Visually see all your points of improvement and address your most critical quality-related issues first</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {isMenuOpen == true && openProduct === "mainCompany" && (
          <div className="company-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 submenu-container"
                  onMouseEnter={() => handleMouseEnter("mainCompany")}
                  onMouseLeave={handleMouseLeave}
                  style={{ padding: "34px" }}
                >
                  <div className="content-leansuite w-100">
                    <div className="row row-cols-2 row-gap-4">
                      <div className="col">
                        <a href="/aboutus" className="text-decoration-none">
                          <div className="d-flex align-items-center gap-2">
                            {/* <NavIcon src={aboutimg} alt="aboutimg" className="me-2" /> */}
                            <MenuNavIcon>
                              <IoMdInformationCircle className="w-auto" size={24} />
                            </MenuNavIcon>
                            <div className="card-content">
                              <LearnMore>About Us</LearnMore>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col">
                        <a href="/theleansuite-partner-community" className="text-decoration-none">
                          <div className="d-flex align-items-center gap-2">
                            {/* <NavIcon src={partnerimg} alt="partnerimg" className="me-2" /> */}
                            <MenuNavIcon>
                            <FaHandsHelping className="w-auto" size={24} />
                            </MenuNavIcon>
                            <div className="card-content">
                              <LearnMore>Partner Network</LearnMore>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col">
                        <a href="/press" className="text-decoration-none">
                          <div className="d-flex align-items-center gap-2">
                            {/* <NavIcon src={pressimg} alt="pressimg" className="me-2" /> */}
                            <MenuNavIcon>
                            <RiFileList3Fill className="w-auto" size={24} />
                            </MenuNavIcon>
                            <div className="card-content">
                              <LearnMore>Press</LearnMore>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col">
                        <a href="/contact-us" className="text-decoration-none">
                          <div className="d-flex align-items-center gap-2">
                            {/* <NavIcon src={contactimg} alt="contactimg" className="me-2" /> */}
                            <MenuNavIcon>
                            <MdEmail className="w-auto" size={24} />
                            </MenuNavIcon>
                            <div className="card-content">
                              <LearnMore>Contact Us</LearnMore>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-wrap" style={{ padding: "34px" }}>
                  <div className="content-leansuite w-100">
                    <img src={aboutmenuimg} className="w-100 rounded mb-2" alt={"menuimg"} />
                  </div>
                </div>
                <div className="col-md-4 pt-40 px-4 d-flex flex-wrap" style={{ padding: "34px" }}>
                  <div className="content-leansuite w-100">
                    <CustomeSpan className="fw-5 mb-2" style={{ fontFamily: Font.basel }}>Our Story</CustomeSpan>
                    <p>In 2021, LeanSuite was created to revolutionize the manufacturing industry by merging lean principles with emerging industrial software technologies. Our end-to-end software focuses on the 3 key aspects of manufacturing–People, Process, and Technology—for increased productivity and waste reduction.</p>
                    <LearnMore href="/aboutus">Learn More</LearnMore>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMenuOpen == true && openProduct === "mainResources" && (
          <div className="company-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 submenu-container"
                  onMouseEnter={() => handleMouseEnter("mainResources")}
                  onMouseLeave={handleMouseLeave}
                  style={{ padding: "34px" }}
                >
                  <div className="content-leansuite w-100">
                    <div className="row row-cols-2 row-gap-4">
                      <div className="col">
                        <a href="/resources/e-books" className="text-decoration-none">
                          <div className="d-flex align-items-center gap-2">
                            {/* <NavIcon src={ebook} alt="ebook" className="me-2" /> */}
                            <MenuNavIcon>
                            <MdCollectionsBookmark className="w-auto" size={24} />
                            </MenuNavIcon>
                            <div className="card-content">
                              <LearnMore>E - Books</LearnMore>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col">
                        <a href="/resources/templates" className="text-decoration-none">
                          <div className="d-flex align-items-center gap-2">
                            {/* <NavIcon src={templates} alt="templates" className="me-2" /> */}
                            <MenuNavIcon>
                            <HiTemplate className="w-auto" size={24} />
                            </MenuNavIcon>
                            <div className="card-content">
                              <LearnMore>Templates</LearnMore>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col">
                        <a href="/resources/videos" className="text-decoration-none">
                          <div className="d-flex align-items-center gap-2">
                            {/* <NavIcon src={videos} alt="videos" className="me-2" /> */}
                            <MenuNavIcon>
                            <BiSolidVideos className="w-auto" size={24} />
                            </MenuNavIcon>
                            <div className="card-content">
                              <LearnMore>Videos</LearnMore>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col">
                        <a href="/resources/webinars" className="text-decoration-none">
                          <div className="d-flex align-items-center gap-2">
                            {/* <NavIcon src={webinars} alt="webinars" className="me-2" /> */}
                            <MenuNavIcon>
                            <TbWorld className="w-auto" size={24} />
                            </MenuNavIcon>
                            <div className="card-content">
                              <LearnMore>Webinars</LearnMore>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col">
                        <a href="/blog" className="text-decoration-none">
                          <div className="d-flex align-items-center gap-2">
                            {/* <NavIcon src={blogs} alt="blogs" className="me-2" /> */}
                            <MenuNavIcon>
                            <FaFileLines className="w-auto" size={24} />
                            </MenuNavIcon>
                            <div className="card-content">
                              <LearnMore>Blogs</LearnMore>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-wrap" style={{ padding: "34px" }}>
                  <div className="content-leansuite w-100">
                    <img src={blog?.featured_image} className="w-100 rounded mb-2" alt={blog?.title} />
                  </div>
                </div>
                <div className="col-md-4 pt-40 px-4 d-flex flex-wrap" style={{ padding: "34px" }}>
                  <div className="content-leansuite w-100">
                    <CustomeSpan className="fw-5 mb-2" style={{ fontFamily: Font.basel }}>Our Story</CustomeSpan>
                    <p>
                      {blog?.excerpt}
                    </p>
                    <LearnMore href="/aboutus">Learn More</LearnMore>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* -------------------------------Mobile Nav------------------------------- */}
      <Navbar
        expand="xl"
        className={`py-2 mobile-nav d-xl-none d-block px-0 ${isSticky ? 'sticky navbar-light' : 'navbar-dark'}`}
        style={{ backgroundColor: isSticky ? '#fff' : '#1c1c4b' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Container>
          <Navbar.Brand>
            <Link to={"/"} >
              <img src={logoSrc} alt="theleansuite logo" className="w-100" style={{ maxWidth: "145px" }} />
            </Link>
          </Navbar.Brand>
          <button
            id="mobile-menu-toggle"
            className="navbar-toggler border-0 shadow-none outline-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMobileMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <Navbar.Collapse className="show" id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown
                  title="Platform"
                  id="platform-dropdown"
                  className="d-xl-none d-block link-nav border-0"
                >
                  <NavDropdown
                    title="LeanSuite 360"
                    id="LeanSuite-dropdown"
                    className="link-nav"
                  >
                    <NavDropdown.Item as={Link} to="/safety-improvement">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Safety Improvement
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/quality-improvement">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Quality Improvement
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/operational-excellence">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Operational Excellence
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/cost-reduction">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Cost Reduction
                      </span>
                    </NavDropdown.Item>

                    <NavDropdown.Item as={Link} to="/workforce-enablement">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Workforce Enablement
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/*  */}
                  <NavDropdown
                    title="Idea"
                    id="LeanSuite-dropdown"
                    className="link-nav"
                  >
                    <NavDropdown.Item as={Link} to="404">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Human Resources
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="404">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Operations
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    title="Failure Tag"
                    id="LeanSuite-dropdown"
                    className="link-nav"
                  >
                    <NavDropdown.Item as={Link} to="/issue-tracker-for-safety">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Safety
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/issue-tracker-for-quality"
                    >
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Quality
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/theleansuite-partner-community"
                      style={{ display: "none" }}
                    >
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        5S/ Workplace Organization
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/issue-tracker-for-operations"
                    >
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Professional Maintenance
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown.Item as={Link} to="/continuous-improvement-for-operations">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Kaizen
                    </span>
                  </NavDropdown.Item>

                  <NavDropdown.Item as={Link} to="/competency-management">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Competencies
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/learning-management-system">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Training
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/loss-and-cost-management">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Loss and Cost Management
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/qa-matrix">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      QA Matrix
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/cilr-management">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      CILR
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/gemba-walker">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Gemba Walker
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/rca">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Root Cause Analysis
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/job-cover-matrix">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Job Cover Matrix
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Company"
                  id="LeanSuite-dropdown"
                  className="d-xl-none d-block link-nav border-0"
                >
                  <NavDropdown.Item as={Link} to="/aboutus">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      About Us
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/careers"
                    style={{ display: "none" }}
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Careers
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/theleansuite-partner-community"
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Partner Network
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/press">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Press
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/"
                    style={{ display: "none" }}
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Customer Success
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/contact-us">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Contact Us
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Resources"
                  id="LeanSuite-dropdown"
                  className="d-xl-none d-block link-nav border-0"
                >
                  <NavDropdown.Item as={Link} to="/resources/e-book">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      E-Books
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/resources/video">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Videos
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/blog">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Blogs
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Item>
                  <Nav.Link
                    eventKey="1"
                    as={Link}
                    to="/home"
                    className="link-nav"
                    style={{ display: "none" }}
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Success
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="1"
                    as={Link}
                    to="/pricing"
                    className="link-nav"
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Pricing
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav.Link eventKey="1" as={Link} to={"https://theleansuite.app/"} className="link-nav">
                <span
                  data-bs-target="#navbarSupportedContent"
                  data-bs-toggle="collapse"
                >
                  Login
                </span>
              </Nav.Link>
              <div className="my-2">
                <Button className="text-decoration-none" onClick={bookDemo} to={"/schedule-demo"}>Schedule demo</Button>
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
export default NewNavbarComponent;
